<template>
  <div class="role-list">
    <page-content>
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        :header-cell-style="{'background': '#D9DDE1', 'color': '#0D0202', 'font-weight': 400}"
        element-loading-text="拼命加载中"
        style="width: 100%"
      >
        <el-table-column
          prop="id"
          label="ID"
          width="90"
        />
        <el-table-column
          prop="goodsImage"
          label="商品图片"
          width="150"
        >
          <template slot-scope="scope">
            <div>
              <img
                style="height: 100px;width: 100px"
                :src="scope.row.goodsImage"
                alt=""
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="goodsName"
          label="商品名称"
          width="120"
        />
        <el-table-column
          prop="skuName"
          label="规格"
        />
        <el-table-column
          prop="sellCount"
          label="数量"
        />
        <el-table-column
          prop="sellPrice"
          label="商品单价（元）"
        />
        <el-table-column
          prop="sellTotal"
          label="商品总价（元）"
        />
        <el-table-column
          prop="discountFee"
          label="店铺优惠（元）"
        />
        <el-table-column
          prop="couponFee"
          label="平台优惠（元）"
        />
        <el-table-column
          prop="commissionFee"
          label="奖励佣金（元）"
        />
        <el-table-column
          prop="payment"
          label="商家实收（元）"
        />
        <el-table-column
          prop="subOrderStatus"
          label="售后状态"
        >
          <template slot-scope="scope">
            {{ ['待支付', '待发货','待收货', '交易成功', '交易关闭'][scope.row.subOrderStatus - 1] }}
          </template>
        </el-table-column>
        <el-table-column
          prop="commissionFee"
          label="结算佣金（元）"
        />
      </el-table>
      <!-- <el-pagination
        v-if="tableData && tableData.length > 0"
        :current-page="pagination.currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pagination.nowPageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.count"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      /> -->
    </page-content>
  </div>
</template>

<script>
import qs from 'qs'

export default {
  name: 'GoodsList',
  props: {
    orderId: {
      type: Number,
      default: ''
    }
  },
  data() {
    return {
      tableData: [],
      loading: false,
      pagination: {
        currentPage: 1,
        nowPageSize: 10,
        count: 0
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 分页控制
    handleSizeChange(val) {
      this.pagination.nowPageSize = val
      this.pagination.currentPage = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val
      this.getList()
    },
    getList() {
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$api.DistributeController.getCommissionDetail,
        data: qs.stringify({
          orderId: this.orderId
        })
      }).then((res) => {
        if (res.code === 0) {
          this.tableData = [res.data]
          this.pagination.count = Number(res.data.total)
        } else {
          this.$message(res.error_msg)
        }
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
</style>
