var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "role-list" },
    [
      _c(
        "page-content",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "header-cell-style": {
                  background: "#D9DDE1",
                  color: "#0D0202",
                  "font-weight": 400,
                },
                "element-loading-text": "拼命加载中",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", width: "90" },
              }),
              _c("el-table-column", {
                attrs: { prop: "goodsImage", label: "商品图片", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          _c("img", {
                            staticStyle: { height: "100px", width: "100px" },
                            attrs: { src: scope.row.goodsImage, alt: "" },
                          }),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "goodsName", label: "商品名称", width: "120" },
              }),
              _c("el-table-column", {
                attrs: { prop: "skuName", label: "规格" },
              }),
              _c("el-table-column", {
                attrs: { prop: "sellCount", label: "数量" },
              }),
              _c("el-table-column", {
                attrs: { prop: "sellPrice", label: "商品单价（元）" },
              }),
              _c("el-table-column", {
                attrs: { prop: "sellTotal", label: "商品总价（元）" },
              }),
              _c("el-table-column", {
                attrs: { prop: "discountFee", label: "店铺优惠（元）" },
              }),
              _c("el-table-column", {
                attrs: { prop: "couponFee", label: "平台优惠（元）" },
              }),
              _c("el-table-column", {
                attrs: { prop: "commissionFee", label: "奖励佣金（元）" },
              }),
              _c("el-table-column", {
                attrs: { prop: "payment", label: "商家实收（元）" },
              }),
              _c("el-table-column", {
                attrs: { prop: "subOrderStatus", label: "售后状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              [
                                "待支付",
                                "待发货",
                                "待收货",
                                "交易成功",
                                "交易关闭",
                              ][scope.row.subOrderStatus - 1]
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "commissionFee", label: "结算佣金（元）" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }