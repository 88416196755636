<template>
  <!-- 佣金明细管理 -->
  <div>
    <page-content>
      <search-panel
        show-btn
        flex
        @getList="getList"
      >
        <!-- <div>
          <p>用户ID</p>
          <el-input
            v-model="name"
            clearable
            placeholder="请输入用户ID"
            size="mini"
          />
        </div> -->
        <div>
          <p>获佣手机号</p>
          <el-input
            v-model="userMobilNumber"
            clearable
            placeholder="请输入获佣手机号"
            size="mini"
          />
        </div>
        <div>
          <p>被分享人手机号</p>
          <el-input
            v-model="beShareUserMobil"
            clearable
            placeholder="请输入被分享人手机号"
            size="mini"
          />
        </div>
        <div>
          <p>订单编号</p>
          <el-input
            v-model="orderNo"
            clearable
            placeholder="请输入订单编号"
            size="mini"
          />
        </div>
      </search-panel>
    </page-content>
    <page-content>
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        :header-cell-style="{'background': '#D9DDE1', 'color': '#0D0202', 'font-weight': 400}"
        element-loading-text="拼命加载中"
        style="width: 100%"
      >
        <el-table-column
          prop="userMobilNumber"
          label="获佣人手机号"
          width="120"
        />
        <el-table-column
          prop="beShareUserMobil"
          label="被分享人手机号"
          width="120"
        />
        <el-table-column
          prop="commissionStatus"
          label="佣金状态"
          width="120"
        >
          <template slot-scope="scope">
            {{ commissionStatusArr[scope.row.commissionStatus - 1] }}
          </template>
        </el-table-column>
        <el-table-column
          prop="endTime"
          label="佣金结算/失效时间"
          width="150"
        >
          <template slot-scope="scope">
            {{ scope.row.commissionClearingTime?setTime(scope.row.commissionClearingTime):'' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="orderNo"
          label="关联订单编号"
        />
        <el-table-column
          prop="relevanceOrderId"
          label="关联订单ID"
        />
        <el-table-column
          prop="commissionTotalValue"
          label="佣金金额（元）"
        />
        <el-table-column
          prop="createTime"
          label="下单时间"
          width="120"
        >
          <template slot-scope="scope">
            {{ setTime(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="status"
          label="关联商品"
        >
          <template slot-scope="scope">
            <div>
              <el-button
                type="primary"
                size="mini"
                @click="operation(scope)"
              >
                查看
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="tableData && tableData.length > 0"
        :current-page="pagination.currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pagination.nowPageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.count"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </page-content>
    <el-dialog
      title="商品信息"
      :visible.sync="goodsDialogVisible"
      :before-close="handleClose"
    >
      <GoodsList
        v-if="goodsDialogVisible"
        :order-id="orderId"
      ></GoodsList>
    </el-dialog>
  </div>
</template>
<script>
import GoodsList from './components/goodsList'
import dayjs from 'dayjs'
// 用户佣金管理
export default {
  name: 'CommissionList',
  components: {
    GoodsList
  },
  data() {
    return {
      name: '',
      mobile: '',
      tableData: [],
      loading: false,
      goodsDialogVisible: false,
      pagination: {
        currentPage: 1,
        nowPageSize: 10,
        count: 0
      },
      tagtime: [],
      startTime: '',
      endTime: '',
      orderId: '',
      beShareUserMobil: '',
      userMobilNumber: '',
      orderNo: '',
      commissionStatusArr: ['已结算', '待结算', '失效']
    }
  },
  watch: {
    tagtime(item) {
      if (item) {
        this.startTime = item[0]
        this.endTime = item[1]
      } else {
        this.startTime = null
        this.endTime = null
      }
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 分页控制
    handleSizeChange(val) {
      this.pagination.nowPageSize = val
      this.pagination.currentPage = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val
      this.getList()
    },
    getList() {
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$api.DistributeController.commissionRecord,
        data: {
          currentPage: this.pagination.currentPage,
          pageSize: this.pagination.nowPageSize,
          startTime: this.startTime,
          endTime: this.endTime,
          commissionStatus: this.commissionStatus,
          relevanceOrderId: this.relevanceOrderId,
          userMobilNumber: this.userMobilNumber,
          beShareUserMobil: this.beShareUserMobil,
          orderNo: this.orderNo
        }
      }).then((res) => {
        if (res.code === 0) {
          this.tableData = res.data.records
          this.pagination.count = Number(res.data.total)
        } else {
          this.$message(res.error_msg)
        }
        this.loading = false
      })
    },
    operation(data) {
      this.goodsDialogVisible = true
      this.orderId = data.row.relevanceOrderId
      console.log(this.orderId, '=======', data)
    },
    handleClose() {
      this.goodsDialogVisible = false
    },
    setTime(time) {
      return dayjs(Number(time)).format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>
